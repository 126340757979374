import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import { BlogPostPreviewGrid } from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Breadcrumb from '../components/layout/breadcrumb'
import SubNavigations from '../components/sub-nav'

import { responsiveTitle1 } from '../components/typography.module.css'

export const query = graphql`
  query PageByTagQuery($tags: String!) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { regex: $tags } } } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          category {
            name
            name_ar
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ArchivePage = props => {
  const { data, errors, pageContext, path = '' } = props
  const { subNav = [], title = '', parentName = '', parentName_ar = '', slug } =
    pageContext || {}
  const _currentNavItemName = path.split('/').pop()
  const currentNavItemIndex = subNav.findIndex(item => item.name === _currentNavItemName)
  const currentNavItem =
    subNav.length > 0 && subNav[currentNavItemIndex]
      ? subNav[currentNavItemIndex].name_ar
      : null

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  let navItemStack = []
  if (parentName_ar) navItemStack.push({ name: parentName_ar, slug: parentName.current })
  navItemStack.push({ name: title, slug })

  return (
    <Layout>
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        {/* <h1 className={responsiveTitle1}>Archive</h1> */}
        <SubNavigations collapsed={true} items={subNav} current={currentNavItem} />
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default ArchivePage

export const Head = ({ location, pageContext }) => (
  <SEO title={`${pageContext?.title}`} pathname={location.pathname}></SEO>
)
